/* 
  ### Customized Chart Components ### 
  Predefined component's behaviors for the Dashboard
*/

// 01_Summaries -------------------------------------------------------------------------------------------------
  // Cubes
    import J2_Cube_A_Data                   from '../01_Summaries/Cubes/Cube_A/includes/primitives/data'
    import J2_Cube_B_Data                   from '../01_Summaries/Cubes/Cube_B/includes/primitives/data'
    import J2_Cube_C_Data                   from '../01_Summaries/Cubes/Cube_C/includes/primitives/data'
    import J2_Cube_BIND_Data                from '../01_Summaries/Cubes/Cube_BIND/includes/primitives/data'
    import J2_Sentence_A_Data               from '../01_Summaries/Cubes/Sentence_A/includes/primitives/data'
    import J2_Week_Performance_Data         from '../01_Summaries/Cubes/Weekly/includes/primitives/data'
    import J2_Week_Performance_UNIT_Data    from '../01_Summaries/Cubes/Weekly_UNIT/includes/primitives/data'
    // Gauges
    import J2_Gauge_Normal_Data             from '../01_Summaries/Gauges/Normal/includes/primitives/data'
    // Tubes    
    import J2_Tube_A_Data                   from '../01_Summaries/Tubes/Normal/includes/primitives/data'
    import J2_Tube_UNIT_Data                from '../01_Summaries/Tubes/Unit/includes/primitives/data'


// 02 DataTables -------------------------------------------------------------------------------------------------
  // Table Charts
  import J2_Progress_Data                 from '../02_DataTables/Chart/Progress/includes/primitives/data'
  import J2_Punch_Data                    from '../02_DataTables/Chart/Punch/includes/primitives/data'
  // Group
  import J3_TBL_GROUP_NORMAL_Data         from '../02_DataTables/Group/Normal/includes/primitives/data'
  // Data Tables    
  import J2_DataTable_A_Data              from '../02_DataTables/Table/ver_A/includes/primitives/data'
  import J2_DataTable_B_Data              from '../02_DataTables/Table/ver_B/includes/primitives/data'
  import J2_DataTable_C_Data              from '../02_DataTables/Table/ver_C/includes/primitives/data'


// 03 PRIMAVERA -------------------------------------------------------------------------------------------------
  // DataTable
  import J2_P6_TABLE_EL_Data              from '../03_Primavera/DataTable/Table_EL/includes/primitives/data'
  // Histogram
  import J2_P6_SCURVE_MINI_Data           from '../03_Primavera/Histogram/SCurve_Mini/includes/primitives/data'
  import J2_P6_TREND_EL_Data              from '../03_Primavera/Histogram/Trend_EL/includes/primitives/data'
  // Summary
  import J2_P6_DONUTS_Data                from '../03_Primavera/Summary/Donuts/includes/primitives/data'
  import J2_P6_DONUTS_R1_Data             from '../03_Primavera/Summary/Donuts_R1/includes/primitives/data'
  import J2_P6_TUBE_EL_Data               from '../03_Primavera/Summary/Tube_EL/includes/primitives/data'


// 04 Common Chart -------------------------------------------------------------------------------------------------
  // Histogram
  import J3_CHT_HIS_AXES_NORMAL_Data      from '../04_Charts/Histogram/Axes_Normal/includes/primitives/data'
  import J3_CHT_HIS_AXES_STACK_Data       from '../04_Charts/Histogram/Axes_Stack/includes/primitives/data'
  // BarChart
  import J3_CHT_BAR_VER_MULTI_Data        from '../04_Charts/BarChart/Ver_Multi/includes/primitives/data'
  import J3_CHT_BAR_VER_STACK_Data        from '../04_Charts/BarChart/Ver_Stack/includes/primitives/data'
  import J3_CHT_BAR_HOZ_MULTI_Data        from '../04_Charts/BarChart/Hoz_Multi/includes/primitives/data'
  // Safety
  import J3_CHT_SFT_INJURY_Data          from '../04_Charts/Safety/Injury/includes/primitives/data'

// 05 Specialized Chart -------------------------------------------------------------------------------------------------
  
  import J2_Skyline_Month_A_Data          from '../05_Specialized/Skyline/Month_A/includes/primitives/data'

// Viewer
  import J2_Filter_Viewer_Data            from '../09_Components/Graphics/Filter_Viewer/includes/primitives/data'
  import J2_SVG_Viewer_Data               from '../09_Components/Graphics/SVG_Viewer/includes/primitives/data'
  import J2_SVG_Viewer_v2_Data            from '../09_Components/Graphics/SVG_Viewer_v2/includes/primitives/data'

// Summary table depth2 for Crisp
import J2_Summary_Table_Data            from '../09_Components/Developing/Summary_Table_Dep2/includes/primitives/data'

//Haneul
import J3_History_Chart_Data           from '../09_TrialVision/Haneul/hisCht/includes/primitives/data'
        


export default {
  // Summaries
    J2_Cube_A_Data,
    J2_Cube_B_Data,
    J2_Cube_C_Data,
    J2_Cube_BIND_Data,
    J2_Sentence_A_Data,
    J2_Week_Performance_Data,
    J2_Week_Performance_UNIT_Data,
    J2_Gauge_Normal_Data,
    J2_Tube_A_Data,
    J2_Tube_UNIT_Data,

  // PREMAVERA
    J2_P6_SCURVE_MINI_Data,
    J2_P6_DONUTS_Data,
    J2_P6_DONUTS_R1_Data,
    J2_P6_TABLE_EL_Data,
    J2_P6_TREND_EL_Data,
    J2_P6_TUBE_EL_Data,

  // Data Tables
    J2_DataTable_A_Data,
    J2_DataTable_B_Data,
    J2_DataTable_C_Data,
    J3_TBL_GROUP_NORMAL_Data,
    J2_Punch_Data,
    J2_Progress_Data,

  // Charts
    J3_CHT_HIS_AXES_NORMAL_Data,
    J3_CHT_HIS_AXES_STACK_Data,
    J3_CHT_BAR_VER_MULTI_Data,
    J3_CHT_BAR_VER_STACK_Data,
    J3_CHT_BAR_HOZ_MULTI_Data,
    J3_CHT_SFT_INJURY_Data,

  // Specialized Chart
    J2_Skyline_Month_A_Data,

  // Viewer
    J2_Filter_Viewer_Data,
    J2_SVG_Viewer_Data,
    J2_SVG_Viewer_v2_Data,

  // Summary table depth2 for Crisp
    J2_Summary_Table_Data,

  // Haneul
    J3_History_Chart_Data,
}
